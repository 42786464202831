<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
              <CCol sm="4">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol sm="8">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonthsRange />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow>
      <CCol sm="12" xxl="10">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol
                sm="12"
                class="d-flex align-items-center justify-content-between"
              >
                {{ "Área de Descargas".toUpperCase() }}
                <CButton
                  class="btn-reload"
                  @click="
                    loadingData = true;
                    makeApiListQuery(selectedStatus);
                  "
                >
                  <CIcon
                    name="cil-reload"
                    :customClasses="`icon reload ${loadingData ? 'spin' : ''}`"
                  />
                </CButton>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12" class="d-flex align-items-center pt-2">
                Mostrar
                <CSelect
                  class="mb-0 ml-2"
                  :options="[
                    { value: '', label: 'Todas' },
                    { value: 'RELEASED', label: messages.common.released },
                    { value: 'LOADING', label: messages.common.pending2 },
                    { value: 'FAILED', label: messages.common.failed },
                  ]"
                  :value.sync="selectedStatus"
                  @change="makeApiListQuery(selectedStatus)"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow v-if="showLoader" class="justify-content-center">
              <Loader
                  width="100px"
                  height="100px"
                  fill-color="#c2c2c2"
                  cssClass="fadeIn anim-speed--1s"
              />
            </CRow>
            <CRow v-if="showTable" class="fadeIn anim-speed--2s">
              <CCol sm="12">
                <table-invoice-downloads
                    :tableData="tableData"
                ></table-invoice-downloads>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TableInvoiceDownloads from "@/components/organisms/TableInvoiceDownloads";
// import jsonData from "@/helpers/dto.json";
import Api from "@/services/Api";
import Loader from "@/components/atoms/Loader";
const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filters-area-combo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const FilterMonthsRange = () =>
  import(
    /* webpackChunkName: "filters-fy-period" */ "@/components/molecules/FilterMonthsRange"
  );
export default {
  name: "InvoicingQuery",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonthsRange,
    TableInvoiceDownloads,
    Loader
  },
  data() {
    return {
      cardCollapse: true,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      areaError: false,
      selectedFY: null,
      selectedSinceMonth: null,
      selectedUpToMonth: null,
      jsonData: [],
      // restriction: 1,
      loadingData: false,
      selectedStatus: "",
      showLoader: false,
      showTable: false,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    tableData() {
      return this.jsonData;
    },
  },
  methods: {
    submitFilterValues(event) {
      event.preventDefault();
      let validated = false;
      if (!this.$store.getters.getFiltersAreaComboParams) {
        this.areaError = true;
      } else {
        this.areaError = false;
        validated = true;
      }
      if (validated) {
        const params = {
          ...this.$store.getters.getFiltersAreaComboParams,
          fiscalYear: this.$store.getters.getFilterFYValue,
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
        };
        console.log(params);
        this.selectedFY = params.fiscalYear;
        this.selectedSinceMonth = params.sinceMonth;
        this.selectedUpToMonth = params.upToMonth;
        Api.sendPost("admin/consultInvoice/request", params).then(
          (response) => {
            console.log("REQUEST: ", response);
            if (response.status === 200 && response.data.code === 200) {
              Api.sendPost("admin/consultInvoice/status", params).then(
                (response) => {
                  console.log("STATUS", response);
                  if (response.status === 200 && response.data.code === 200) {
                    if (response.data.data) {
                      this.jsonData.unshift(response.data.data);
                    } else {
                      console.log("Data:", response.data.data)
                    }
                  }
                }
              );
            }
          }
        );
      }
    },
    makeApiListQuery(invoiceStatus) {
      this.showLoader = true;
      this.showTable = false;
      Api.sendGet(`admin/consultInvoice/list?status=${invoiceStatus}`).then(
        (response) => {
          if (response.status === 200 && response.data.code === 200) {
            this.showLoader = false;
            this.showTable = true;
            if (response.data.data) {
              this.jsonData = response.data.data.reverse();
            }
          } else {
            this.showLoader = false;
            this.showTable = true;
            console.error(`List query ERROR -> ${response.status} - ${response.data.code} ${response.data.message}`)
          }
          setTimeout(() => (this.loadingData = false), 300);
        }
      );
    },
  },
  created() {
    this.makeApiListQuery(this.selectedStatus);
  },
};
</script>
<style lang="scss">
.icon {
  &.reload {
    width: 1.3rem;
    height: 1.3rem;
  }
}
.btn-reload {
  &:hover {
    background-color: #ebedef;
  }
  &:focus {
    box-shadow: none;
  }
}
</style>
