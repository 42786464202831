var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CForm',{on:{"submit":_vm.submitFilterValues}},[_c('CCard',[_c('CButton',{staticClass:"flex flex--space-between text-left shadow-none card-header",attrs:{"tag":"button","color":"link"},on:{"click":function($event){_vm.cardCollapse = !_vm.cardCollapse}}},[_c('h5',{staticClass:"m-0"},[_vm._v("Filtros")]),_c('CIcon',{attrs:{"name":`cil-chevron-${_vm.cardCollapse ? 'top' : 'bottom'}`}})],1),_c('CCollapse',{attrs:{"show":_vm.cardCollapse}},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('label',{staticClass:"filter-label"},[_vm._v(_vm._s(_vm.messages.filters.area))]),_c('FiltersAreaCombo',{attrs:{"activeElements":_vm.activeItems}}),(_vm.areaError)?_c('span',{staticClass:"error-message error-message--bottom"},[_vm._v(_vm._s(_vm.messages.validation.filterAreaCombo))]):_vm._e()],1),_c('CCol',{attrs:{"sm":"4"}},[_c('label',{staticClass:"filter-label"},[_vm._v("FY")]),_c('FilterFY')],1),_c('CCol',{attrs:{"sm":"8"}},[_c('label',{staticClass:"filter-label"},[_vm._v(_vm._s(_vm.messages.filters.period))]),_c('FilterMonthsRange')],1)],1)],1),_c('CCardFooter',[_c('CButton',{attrs:{"type":"submit","color":"primary"}},[_vm._v("Mostrar")])],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12","xxl":"10"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-center justify-content-between",attrs:{"sm":"12"}},[_vm._v(" "+_vm._s("Área de Descargas".toUpperCase())+" "),_c('CButton',{staticClass:"btn-reload",on:{"click":function($event){_vm.loadingData = true;
                  _vm.makeApiListQuery(_vm.selectedStatus);}}},[_c('CIcon',{attrs:{"name":"cil-reload","customClasses":`icon reload ${_vm.loadingData ? 'spin' : ''}`}})],1)],1)],1),_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-center pt-2",attrs:{"sm":"12"}},[_vm._v(" Mostrar "),_c('CSelect',{staticClass:"mb-0 ml-2",attrs:{"options":[
                  { value: '', label: 'Todas' },
                  { value: 'RELEASED', label: _vm.messages.common.released },
                  { value: 'LOADING', label: _vm.messages.common.pending2 },
                  { value: 'FAILED', label: _vm.messages.common.failed },
                ],"value":_vm.selectedStatus},on:{"update:value":function($event){_vm.selectedStatus=$event},"change":function($event){return _vm.makeApiListQuery(_vm.selectedStatus)}}})],1)],1)],1),_c('CCardBody',[(_vm.showLoader)?_c('CRow',{staticClass:"justify-content-center"},[_c('Loader',{attrs:{"width":"100px","height":"100px","fill-color":"#c2c2c2","cssClass":"fadeIn anim-speed--1s"}})],1):_vm._e(),(_vm.showTable)?_c('CRow',{staticClass:"fadeIn anim-speed--2s"},[_c('CCol',{attrs:{"sm":"12"}},[_c('table-invoice-downloads',{attrs:{"tableData":_vm.tableData}})],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }