const concessionOptions = (array) => {
  const resultArray = [];
  array.forEach((elem) => {
    let item = {
      value: elem.concessionCode,
      label: `${elem.concessionCode} - ${elem.businessName}`,
    };
    resultArray.push(item);
  });
  return resultArray;
};

const optionsAndValues = (array, lang) => {
  const ArrayResult = [];

  const valuesByCode = {
    ESP: {
      es: "ESPAÑA",
      pt: "ESPANHA",
    },
    PRT: {
      es: "PORTUGAL",
      pt: "PORTUGAL",
    },
    IBR: {
      es: "IBERIA",
      pt: "IBERIA",
    },
    CN: {
      es: "CENTRO-NORTE",
      pt: "CENTRO-NORTE",
    },
    NE: {
      es: "NORESTE",
      pt: "NORESTE",
    },
    PT: {
      es: "PORTUGAL",
      pt: "PORTUGAL",
    },
    SE: {
      es: "SURESTE",
      pt: "SUDESTE",
    },
  };

  array.forEach((element) => {
    let item = {
      value: element,
      label: valuesByCode[element][lang],
    };
    ArrayResult.push(item);
  });

  return ArrayResult;
};

export default {
  concessionOptions,
  optionsAndValues,
};
